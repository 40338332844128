import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import routes from "./config/routes.json";
import IndexPage from "./pages/IndexPage";

import "./stylesheets/app.scss";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={routes.index} element={<IndexPage />} />
          <Route path={routes.question} element={<IndexPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
