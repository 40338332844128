import * as React from "react";
import { useNavigate } from "react-router-dom";
import questions from "../../contents/questions";
import HelpdeskHelper from "../../helpers/HelpdeskHelper";
import UrlHelper from "../../helpers/UrlHelper";
import Area from "./Area";
import Question from "./Question";

const Helpdesk = (props) => {
  const navigate = useNavigate();
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);

  React.useEffect(() => {
    if (props && props.questionSlug) {
      const question = HelpdeskHelper.getQuestion(props.questionSlug);
      if (question) setSelectedQuestion(question);
    }
  }, [props.questionSlug]);

  const closeQuestion = () => {
    setSelectedQuestion(null);
    navigate(UrlHelper.url("index"));
  };

  const actions = { closeQuestion };

  return (
    <div className="helpdesk-helpdesk">
      {Object.keys(questions).map((areaKey) => {
        return <Area key={areaKey} area={questions[areaKey]} />;
      })}
      {selectedQuestion && (
        <Question question={selectedQuestion} actions={actions} />
      )}
    </div>
  );
};

export default Helpdesk;
