import React from "react";
import { Cell, Grid, Row } from "../_foundation/_grid";

function Footer(props) {
  return (
    <footer>
      <Grid>
        <Row>
          <Cell sm={24}>
            &copy; 2022 membermate |{" "}
            <a
              href="https://membermate.de/impressum"
              target="_blank"
              rel="noopener noreferrer"
            >
              Impressum
            </a>{" "}
            |{" "}
            <a
              href="https://membermate.de/datenschutz"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutz
            </a>{" "}
            |{" "}
            <a
              href="https://membermate.de/agb"
              target="_blank"
              rel="noopener noreferrer"
            >
              AGB
            </a>
          </Cell>
        </Row>
      </Grid>
    </footer>
  );
}

export default Footer;
