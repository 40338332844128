import routes from "../config/routes.json";

const UrlHelper = {
  url: function (path, urlParams) {
    let result = routes;

    try {
      let pathSteps = path.split(".");

      pathSteps.map((step) => {
        result = result[step];
        return null;
      });
    } catch (exception) {
      result = path;
    }

    if (result && urlParams) {
      Object.keys(urlParams).map((urlParamKey) => {
        result = result.replace(`:${urlParamKey}`, urlParams[urlParamKey]);
        return null;
      });
    }

    //console.log("config", result);

    return result;
  },
};

export default UrlHelper;
