import * as React from "react";
import { Cell, Grid, Row } from "../_foundation/_grid";
import Category from "./Category";

const Area = ({ area }) => {
  return (
    <div className="helpdesk-area">
      <h2>{area.title}</h2>
      <Grid type="full">
        <Row margin="xy">
          {Object.keys(area.categories).map((categoryKey) => {
            const category = area.categories[categoryKey];
            category.areaSlug = area.slug;
            return (
              <Cell sm={24} md={12} key={categoryKey}>
                <Category category={category} />
              </Cell>
            );
          })}
        </Row>
      </Grid>
    </div>
  );
};

export default Area;
