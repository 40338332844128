import helpdesk from "../contents/questions";

const HelpdeskHelper = {
  getQuestion: function (questionSlug) {
    let foundQuestion = null;

    Object.keys(helpdesk).map((areaKey) => {
      Object.keys(helpdesk[areaKey].categories).map((categoryKey) => {
        helpdesk[areaKey].categories[categoryKey].questions.map((question) => {
          if (question.slug === questionSlug) foundQuestion = question;
          return null;
        });
        return null;
      });
      return null;
    });

    return foundQuestion;
  },
};

export default HelpdeskHelper;
