import * as React from "react";

const helpdesk = {
  membermate: {
    slug: "membermate",
    title: "membermate",
    categories: {
      basic: {
        slug: "grundlegende-fragen",
        title: "Grundlegende Fragen",
        questions: [
          {
            slug: "wie-kann-ich-videos-in-kurs-einbinden",
            q: "Wie kann ich Videos in meinen Kurs einbinden?",
            a: (
              <>
                <p>Dazu bieten wir Dir verschiedene Möglichkeiten:</p>
                <ol>
                  <li>
                    <strong>
                      Videos und Audiodateien direkt bei membermate hochladen
                    </strong>
                    <br />
                    Du kannst Deine Videos direkt bei membermate hochladen. Die
                    Videos werden in verschiedenen Qualitätsstufen und Größen
                    auf deutschen Servern gespeichert.
                    <br />
                    &nbsp;
                  </li>
                  <li>
                    <strong>Vimeo</strong>
                    <br />
                    Vimeo bietet ein sehr umfangreiches Videohosting mit guten
                    Datenschutzeinstellungen. Solltest Du einen externen
                    Videohoster nutzen wollen, geht unsere Empfehlung klar in
                    Richtung Vimeo. Im größten Vimeo-Paket kannst Du gleich
                    Livestreams mitbuchen, die Du direkt in Deinen membermate
                    Mitgliederbereich einbinden kannst.
                    <br />
                    &nbsp;
                  </li>
                  <li>
                    <strong>Youtube</strong>
                    <br />
                    Youtube bietet sich gut als kostenfreier Videohoster an.
                    Allerdings besteht hier der Nachteil, dass die URLs von
                    Videos weitergegeben und von Dritten angeschaut werden
                    könnten, selbst wenn das Video als privat gelistet
                    eingestellt ist. Daher empfehlen wir die Einbindung von
                    Youtube-Videos nur bei Videos, die nicht exklusiv für die
                    zahlenden Mitglieder gedacht sind.
                    <br />
                    &nbsp;
                  </li>
                  <li>
                    <strong>Sonstige Hoster mit HTML-Embed-Code</strong>
                    <br />
                    Darüber hinaus hast Du die Möglichkeit, Deine Videos bei
                    jedem anderen Hoster hochzuladen, sofern dieser einen
                    HTML-Embed-Code zur Verfügung stellt, mit dem das Video
                    eingebunden werden kann.
                    <br />
                    &nbsp;
                  </li>
                </ol>
              </>
            ),
          },
          {
            slug: "wie-kommen-kaeufer-in-mitgliederbereich",
            q: "Wie kommen meine Käufer in den Mitgliederbereich? ",
            a: (
              <>
                <p>Hierfür gibt es mehrere Möglichkeiten.</p>
                <ol>
                  <li>
                    <strong>Manuelle Freischaltung</strong>
                    <br />
                    In Deinem Administrationsbereich kannst Du beliebig viele
                    Mitglieder hinzufügen. Jedem Mitglied kannst Du manuell
                    Zugang zu bestimmten Kursen und Produkten gewähren. Deine
                    Mitglieder erhalten dann eine E-Mail mit weiteren
                    Informationen, wie sie Zugriff auf den Mitgliederbereich
                    bekommen.
                    <br />
                    &nbsp;
                  </li>
                  <li>
                    <strong>
                      Automatische Zahlung über Digistore24, elopage oder
                      CopeCart
                    </strong>
                    <br />
                    Du kannst die Zahlungsanbieter
                    <br />
                    <br />
                    Digistore24
                    <br />
                    elopage
                    <br />
                    CopeCart
                    <br />
                    <br />
                    mit Deinem Mitgliederbereich verknüpfen. Beim Kauf eines
                    Produkts erstellt der Mitgliederbereich automatisch das
                    Benutzerkonto, sofern es mit der angegebenen E-Mail-Adresse
                    noch keines gibt, und sendet eine E-Mail mit weiteren
                    Informationen zum Zugriff auf den Mitgliederbereich heraus.
                    bekommen.
                    <br />
                    &nbsp;
                  </li>
                  <li>
                    <strong>Automatische Freischaltung via Webhook</strong>
                    <p>
                      Du kannst gern einen anderen Zahlungsanbieter als die oben
                      genannten verwenden. Mit Hilfe des integrierten membermate
                      Webhooks kannst Du den Drittanbieter mit Deinem membermate
                      Mitgliederbereich verknüpfen.
                    </p>
                    <p>
                      Ab voraussichtlich April 2022 wirst Du für diesen Zweck
                      zusätzlich auch Zapier einsetzen können.
                    </p>
                    &nbsp;
                  </li>
                </ol>
              </>
            ),
          },
          {
            slug: "brauche-ich-eine-facebook-gruppe",
            q: "Brauche ich zusätzlich eine Facebook-Gruppe?",
            a: (
              <>
                <p>
                  <strong>Die ganz klare Antwort: Nein.</strong>
                </p>
                <p>
                  Unser Ziel mit membermate ist es, Dir die Möglichkeit zu
                  bieten, einen Ort zu erschaffen, an dem Deine Mitglieder alles
                  vorfinden, was sie brauchen. All Deine Kurse und Inhalte, die
                  Live Calls, ggfs. Routinentracker und Visionboard, aber vor
                  allem:{" "}
                  <strong>
                    Einen Bereich, in dem sie sich untereinander austauschen
                    können.
                  </strong>
                </p>
                <p>
                  Deshalb haben wir den Community-Bereich direkt in membermate
                  integriert. Als eine Kombination aus klassischen Foren (für
                  die Übersicht) und dem Aufbau von Beiträgen aus
                  Facebook-Gruppen bietet der Community-Bereich Dir und Deinen
                  Mitgliedern viele Möglichkeiten, untereinander in Kontakt zu
                  bleiben.
                </p>
                <p>
                  <strong>
                    Das erspart Dir auch die manuelle Freischaltung aller
                    Mitglieder in der Facebook-Gruppe.
                  </strong>{" "}
                  Anstatt bei jeder Gruppen-Beitrittsanfrage die E-Mail-Adresse
                  mit Deiner Käuferliste abgleichen zu müssen, kannst Du
                  einzelne Bereiche und Boards Deines integrierten
                  Community-Bereichs mit den passenden Kursen und Produkten
                  verknüpfen - und die Freischaltung passiert vollautomatisch.
                </p>
              </>
            ),
          },
          {
            slug: "muss-ich-logo-und-farben-von-membermate-verwenden",
            q: "Muss ich das Logo und die Farben von membermate verwenden?",
            a: (
              <>
                <p>
                  Nein. Du kannst das Logo, das Favicon und sämtliche Farben an
                  Dein Branding anpassen. membermate tritt somit vollkommen in
                  den Hintergrund.
                </p>
              </>
            ),
          },
          {
            slug: "kann-ich-eigene-domain-verwenden",
            q: "Kann ich meine eigene Domain verwenden?",
            a: (
              <>
                <p>
                  Ja! Beim Start der Testphase wählst Du zunächst eine
                  membermate.de- oder membermate.at-Subdomain aus, damit Dein
                  Mitgliederbereich erst einmal eingerichtet werden kann und Du
                  alles ausprobieren kannst.
                </p>

                <p>
                  Solltest Du Dich entscheiden, membermate zu nutzen, schreibe
                  einfach unseren Support an und wir verknüpfen Deine Domain
                  kostenfrei und ohne zusätzliche laufende Kosten unsererseits.
                  (Die Kosten für die Domain musst Du natürlich weiterhin an
                  Deinen Provider zahlen).
                </p>
              </>
            ),
          },
          {
            slug: "wie-kann-ich-live-calls-anbieten",
            q: "Wie kann ich Live Calls anbieten? ",
            a: (
              <>
                <p>Dazu bieten wir Dir verschiedene Möglichkeiten:</p>
                <ol>
                  <li>
                    <strong>Vimeo Live Events</strong>
                    <br />
                    Du kannst Vimeo Live Calls ganz einfach in Deinen
                    Mitgliederbereich integrieren, indem Du die ID des Vimeo
                    Livestreams im Adminbereich hinterlegst. Vimeo Live Calls
                    sind "one-way", d.h. Deine Mitglieder können Dich sehen, Du
                    aber sie nicht. Vimeo Live Calls sind nach aktuellem Stand
                    nur im größten Vimeo-Paket enthalten.
                    <br />
                    &nbsp;
                  </li>
                  <li>
                    <strong>Youtube Livestreams</strong>
                    <br />
                    Eine kostenfreie Alternative sind Youtube Livestreams. Die
                    kannst Du ebenfalls per Hinterlegung der Livestream-ID ganz
                    einfach mit Deinem Mitgliederbereich verbinden. Allerdings
                    haben Youtube-Livestreams den Nachteil, das sie auch von
                    Unbefugten angesehen werden können, wenn die Adresse des
                    Livestreams weitergegeben wird, selbst wenn der Livestream
                    bei Youtube als privat gelistet wird.
                    <br />
                    &nbsp;
                  </li>
                  <li>
                    <strong>Zoom</strong>
                    <p>
                      Gleich vorweg: Zoom-Calls können leider nicht direkt in
                      den Mitgliederbereich eingebunden werden. Warum nicht? Es
                      gibt zwar theoretisch die Möglichkeit, aber zum einen
                      funktioniert das auf iOS und iPadOS nicht optimal und zum
                      anderen wird die Bildqualität seitens Zoom auf 360p
                      begrenzt. Aus diesen Gründen haben wir nach Absprache mit
                      unserer Community eine weitere Möglichkeit eingebaut:
                    </p>
                    <p>
                      <strong>Zoom-Links</strong>
                      <br />
                      Du kannst beim Anlegen eines Live Calls in Deinem
                      Administrationsbereich den Zoom-Link hinterlegen. Dieser
                      Link wird Deinen Mitgliedern dann nach Ablauf des
                      Countdowns auf der Home-Seite automatisch angezeigt. Per
                      Klick auf den Link öffnet sich wie gewohnt die Zoom-App
                      und Deine Mitglieder können teilnehmen. Die Aufzeichnung
                      kannst Du anschließend ganz einfach in eine Lektion
                      hochladen.
                    </p>
                    <p>
                      Auf diese Weise brauchst Du die Zoom-Links nicht mehr
                      unbedingt per Mail herumschicken, da sie an einem
                      zentralen Ort im Mitgliederbereich abrufbar sind.
                    </p>
                    &nbsp;
                  </li>
                  <li>
                    <strong>HTML-Embed-Code</strong>
                    <br />
                    Solltest Du Deine Livestreams über einen anderen Anbieter
                    durchführen wollen, der einen HTML-Embed-Code zur Verfügung
                    stellt, kannst Du diesen Code im Adminbereich hinterlegen,
                    sodass der Livestream direkt im Mitgliederbereich angezeigt
                    wird.
                    <br />
                    &nbsp;
                  </li>
                </ol>
                <p>
                  <strong>Live Chats</strong>
                  <br />
                  Bei den direkt in den Mitgliederbereich eingebundenen
                  Livestreams kannst Du einen integrierten Livechat aktivieren,
                  über den Deine Mitglieder während des Live Calls untereinander
                  kommunizieren und Dir Fragen stellen können.
                </p>
                <p>
                  <strong>Integrierte Lösung</strong>
                  <br />
                  Wir arbeiten daran, eine Livestream-Lösung direkt in
                  membermate zu integrieren. Da Livestreams aber sehr
                  herausfordernde Anforderungen an Programmierung, Technik und
                  Server haben, damit sie sauber, stabil und flüssig laufen,
                  wird es noch einen Moment dauern, bis wir dieses Feature
                  anbieten können.
                </p>
              </>
            ),
          },
        ],
      },
    },
  },
  whitelabel: {
    slug: "white-label-mitgliederbereich",
    title: "Dein White Label Mitgliederbereich",
    categories: {
      design: {
        slug: "branding-design",
        title: "Branding & Design",
        questions: [
          {
            slug: "design-mitgliederbereich-anpassen",
            q: "Wie kann ich das Design meines Mitgliederbereichs anpassen?",
            a: (
              <>
                <p>
                  Mit Hilfe unseres{" "}
                  <strong>
                    Design-Tools, das Du in Deinem Adminbereich findest
                  </strong>
                  , kannst Du sämtliche Farben und Schriftarten anpassen.
                </p>
                <p>
                  In Deinem Adminbereich unter Einstellungen hast Du zusätzlich
                  die Möglichkeit, Dein{" "}
                  <strong>
                    eigenes Logo, Dein eigenes Favicon und eigene
                    Hintergrundbilder
                  </strong>{" "}
                  hochzuladen.
                </p>
                <p>
                  Darüber hinaus kannst Du die{" "}
                  <strong>
                    Hintergrundbilder der Kacheln/Boxen auf der Home-Seite
                  </strong>{" "}
                  ebenfalls ändern. Gehe in Deinem Administrationsbereich
                  einfach zum Punkt Home-Seite und wähle das gewünschte Layout
                  und anschließend die gewünschte Kachel aus.
                </p>
              </>
            ),
          },
          {
            slug: "wie-kann-ich-eigene-domain-verwenden",
            q: "Wie kann ich eine andere bzw. meine eigene Domain verwenden?",
            a: (
              <>
                <p>
                  <strong>membermate Subdomain</strong>
                </p>
                <p>
                  Beim Start Deiner Testphase hast Du eine Subdomain für Deinen
                  Mitgliederbereich ausgewählt (xxxxxx.membermate.de oder
                  xxxxxx.membermate.at, wo bei xxxxxx für den von Dir
                  ausgewählten Namen steht).
                </p>
                <p>
                  Du kannst die Subdomain jederzeit ändern. Da dazu aber auf
                  unserer Seite manuelle Schritte erforderlich sind, schreibe
                  uns bitte eine kurze E-Mail mit der gewünschten Domainänderung
                  an support@membermate.de.
                </p>
                <p>
                  Bitte beachte, dass es keine Garantie gibt, dass der
                  gewünschte Subdomainname noch frei ist. Außerdem behalten wir
                  uns das Recht vor, gewisse Domainnamen abzulehnen. Das kann
                  z.B. der Fall sein, wenn die URL des Mitgliederbereichs
                  irreführend sein könnte, z.B. support.membermate.de,
                  technik.membermate.de o.ä. oder wenn die gewünschte Subdomain
                  gegen geltendes Recht verstoßen, pornografisch, rassistisch,
                  diskriminierend o.ä. sein sollte.
                </p>

                <p>
                  <strong>Eigene Domain verwenden</strong>
                </p>
                <p>
                  Du kannst auch Deine eigene Domain bzw. Subdomain verwenden.
                  Wenn Du z.B. Eigentümer der Domain annamueller.de bist, kannst
                  Du jede beliebige Subdomain von annamueller.de mit Deinem
                  membermate Mitgliederbereich verknüpfen, also z.B.
                </p>
                <p>
                  mitglieder.annamueller.de
                  <br />
                  members.annamueller.de
                  <br />
                  community.annamueller.de
                  <br />
                  kurse.annamueller.de
                </p>
                <p>und so weiter.</p>
                <p>
                  Für die Hinterlegung einer eigenen Domain sind folgende
                  Schritte notwendig:
                </p>
                <ol>
                  <li>
                    Schreibe uns eine E-Mail mit der gewünschten Domainänderung
                    an support@membermate.de.
                  </li>
                  <li>
                    Anschließend musst Du eine s.g. DNS-Änderung an Deiner
                    Domain vornehmen. Damit gibst Du uns im Grunde offiziell und
                    technisch die Erlaubnis, Deine Domain mit unserem Server zu
                    verbinden. Dieser Schritt ist notwendig, damit nicht einfach
                    jeder Beliebige Deine Domains nutzen kann.
                    <br />
                    Du erhältst dann eine E-Mail von uns mit weiteren
                    Informationen, wie Du die DNS-Änderung vornehmen kannst.
                    Solltest Du die Änderungen nicht selbst vornehmen können,
                    unterstützen wir Dich natürlich gern.
                  </li>
                  <li>
                    Nachdem Du die DNS-Änderungen vorgenommen hast, gibst Du uns
                    eine kurze Info und wir können Deine Domain an unserem
                    Server hinterlegen und mit Deinem Mitgliederbereich
                    verknüpfen.
                  </li>
                  <li>Fertig!</li>
                </ol>
              </>
            ),
          },
        ],
      },
    },
  },
  abo: {
    slug: "membermate-abo",
    title: "Dein membermate Abonnement",
    categories: {
      trial: {
        slug: "testphase",
        title: "Kostenfreie Testphase",
        questions: [
          {
            slug: "bietet-ihr-testphase-an",
            q: "Bietet Ihr eine Testphase an?",
            a: (
              <>
                <p>
                  Ja! Du kannst Dir kostenfrei einen membermate
                  Mitgliederbereich einrichten und alles testen. Selbst nach
                  Ablauf der 14 tägigen Testphase hast Du weiterhin Zugriff auf
                  alle Funktionen und kannst Deinen Mitgliederbereich weiter
                  füllen.
                </p>
                <p>
                  Der einzige Unterschied: Nach Ablauf der 14 Tage können sich
                  nur noch Administratoren einloggen, aber keine "normalen"
                  Mitglieder mehr. Damit möchten wir Dir die Möglichkeit geben,
                  die Funktionen von membermate auch über die 14 Tage hinaus
                  ausgiebig testen zu können. Außerdem kannst Du Deinen
                  Mitgliederbereich erstmal in Ruhe vollständig einrichten und
                  musst erst etwas bezahlen, sobald Du die Tore für alle
                  öffnest.
                </p>
              </>
            ),
          },
          {
            slug: "testphase-zahlungsdaten",
            q: "Muss ich für die Testphase Zahlungsdaten angeben?",
            a: (
              <>
                <p>
                  Nein. Für das Starten der Testphase musst Du lediglich Deinen
                  Namen und eine gültige E-Mail-Adresse eingeben. Erst wenn Du
                  Dich entscheidest, ein membermate Paket zu buchen, fragen wir
                  Zahlungsdaten ab.
                </p>
              </>
            ),
          },
          {
            slug: "testphase-aktiv-kuendigen",
            q: "Muss ich die Testphase aktiv kündigen?",
            a: (
              <>
                <p>
                  Nein. Nach Ablauf der 14 tägigen Testphase können sich nur
                  noch Administratoren einloggen, aber keine "normalen"
                  Mitglieder mehr. Damit möchten wir Dir die Möglichkeit geben,
                  die Funktionen von membermate auch über die 14 Tage hinaus
                  ausgiebig testen zu können. Außerdem kannst Du Deinen
                  Mitgliederbereich erstmal in Ruhe vollständig einrichten und
                  musst erst etwas bezahlen, sobald Du die Tore für alle
                  öffnest.
                </p>
                <p>
                  Nach Ablauf der Testphase musst Du aktiv ein membermate Paket
                  über Deinen Administrationsbereich buchen, um Deinen
                  Mitgliederbereich uneingeschränkt nutzen zu können. Solltest
                  Du kein Paket buchen, wird Dir natürlich auch nichts
                  berechnet.
                </p>
                <p>
                  Dein Mitgliederbereich bleibt aber zunächst bestehen, damit Du
                  ihn wie oben beschrieben ganz in Ruhe einrichten oder zu einem
                  späteren Zeitpunkt reaktivieren kannst. Solltest Du die
                  Löschung Deines Mitgliederbereichs und aller damit verbundenen
                  Daten wünschen, sende uns bitte eine entsprechende E-Mail an
                  support@membermate.de
                </p>
              </>
            ),
          },
        ],
      },
      payment: {
        slug: "zahlung-kuendigung",
        title: "Zahlung & Kündigung",
        questions: [
          {
            slug: "welche-zahlungsarten",
            q: "Welche Zahlungsarten bietet Ihr an?",
            a: (
              <>
                <p>
                  Zum aktuellen Zeitpunkt kannst Du Dein membermate Abonnement
                  mit Kreditkarte oder per SEPA-Lastschrift bezahlen. Solltest
                  Du eine andere Zahlungsart, wie z.B. Paypal bevorzugen,
                  schreibe uns gern eine E-Mail an support@membermate.de, wir
                  finden sicherlich eine Lösung.{" "}
                </p>
                <p>
                  Die Zahlungsabwicklung erfolgt über unseren Zahlungspartner
                  Stripe. Direkt nach Abschluss der Bezahlung erhältst Du die
                  Rechnung per E-Mail zugesandt.
                </p>
              </>
            ),
          },
          {
            slug: "welche-zahlungsintervalle",
            q: "Welche Zahlungsintervalle bietet Ihr an?",
            a: (
              <>
                <p>
                  Du kannst wahlweise monatlich oder jährlich im Voraus buchen.
                  Solltest Du Dich für eine jährliche Vertragsdauer entscheiden,{" "}
                  <strong>bedanken wir uns bei Dir mit einem Rabatt</strong>.
                  Die jeweils gültigen monatlichen und jährlichen Preise findest
                  Du unter Preise oder unter dem Punkt Support im
                  Administrationsbereichs Deines MIigliederbereichs.
                </p>
              </>
            ),
          },
          {
            slug: "wo-finde-ich-rechnungen",
            q: "Wo finde ich meine Rechnungen?",
            a: (
              <>
                <p>
                  Solltest Du <strong>am oder nach dem 23.11.2021</strong>{" "}
                  gebucht haben, kannst Du Deine Rechnungen auf zwei Arten
                  aufrufen:
                </p>
                <ol>
                  <li>
                    Nach jeder Zahlung erhältst Du eine E-Mail mit der
                    dazugehörigen Rechnung als PDF.
                  </li>
                  <li>
                    Unter dem Punkt Support im Adminbereich Deines
                    Mitgliederbereichs findest Du den Reiter Rechnungen. Dort
                    kannst Du alle Rechnungen nachträglich herunterladen.
                  </li>
                </ol>
                <p>
                  Solltest Du <strong>vor dem 23.11.2021</strong> gebucht haben,
                  werden Deine Zahlungen über den vorherigen Zahlungsanbieter
                  Digistore24 abgewickelt. In diesem Fall erhältst Du die
                  Rechnungen nicht von membermate, sondern direkt von
                  Digistore24. Bitte überprüfe die E-Mails, die Du von
                  Digistore24 erhalten hast. Dort findest Du einen Link zu den
                  Rechnungen.
                </p>
              </>
            ),
          },
          {
            slug: "wie-sind-kuendigungsfristen",
            q: "Wie sind die Kündigungsfristen?",
            a: (
              <>
                <p>
                  Die Kündigungsfrist beträgt sieben (7) Werktage bis zum Ende
                  der Laufzeit Deines membermate Abonnements.
                </p>
                <p>
                  Bei monatlicher Zahlweise ist das also sieben Werktage vor
                  Ablauf des bereits bezahlten Monats. Bei jährlicher Zahlweise
                  entsprechend sieben Werktage vor Ablauf des bereits bezahlten
                  Jahres.
                </p>
                <p>
                  Sende uns zur Kündigung einfach eine E-Mail an
                  support@membermate.de
                </p>
              </>
            ),
          },
          {
            slug: "paketwechsel-moeglich",
            q: "Ist ein Paketwechsel möglich?",
            a: (
              <>
                <p>
                  Ja, ein Wechsel in ein kleineres oder größeres Paket ist
                  möglich. Aktuell gibt es noch keinen automatisierten Weg für
                  den Wechsel. Schreibe uns daher einfach eine kurze E-Mail an
                  support@membermate.de und teile uns den Wechsel in Dein
                  gewünschtes Paket mit.
                </p>
              </>
            ),
          },
        ],
      },
    },
  },
};

export default helpdesk;
