import * as React from "react";
import { Grid, Row, Cell } from "../_foundation/foundation";
import membermateLogo from "../../assets/membermate-red.png";
import Footer from "./Footer";

const MainLayout = (props) => {
  return (
    <div className="main-layout">
      <main>
        <Grid>
          <Row margin="xy">
            <Cell sm={24}>
              <div className="top-header">
                <div className="logo">
                  <img src={membermateLogo} alt="membermate" />
                </div>
                <h1>membermate Helpdesk</h1>
              </div>
              {props.children}
            </Cell>
          </Row>
        </Grid>
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
