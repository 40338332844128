import * as React from "react";
import { Link } from "react-router-dom";
import Box from "../reusables/Box/Box";
import { Icon } from "../_foundation/_buttons";
import UrlHelper from "../../helpers/UrlHelper";

const Category = ({ category }) => {
  return (
    <div className="helpdesk-category">
      <Box>
        <h3>{category.title}</h3>
        {!category.questions || category.questions.length === 0 ? (
          <div className="no-questions">
            Es gibt noch keine Fragen in dieser Kategorie.
          </div>
        ) : (
          <>
            <ul className="helpdesk-category-questions">
              {category.questions.map((question, index) => {
                const questionUrl = UrlHelper.url("question", {
                  areaSlug: category.areaSlug,
                  categorySlug: category.slug,
                  questionSlug: question.slug,
                });
                return (
                  <li key={index}>
                    <Link to={questionUrl}>
                      <Icon icon="question-circle-o" left /> {question.q}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </Box>
    </div>
  );
};

export default Category;
