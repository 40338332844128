import React from "react";
import MMPopup from "../reusables/MMPopup/MMPopup";
import membermateLogo from "../../assets/membermate-red.png";

function Question({ question, actions }) {
  return (
    <div className="helpdesk-question-popup">
      <MMPopup
        size="medium"
        show={true}
        handleClose={() => {
          actions.closeQuestion();
        }}
      >
        <div className="question-popup-logo">
          <img src={membermateLogo} alt="membermate" />
        </div>
        <h2>{question.q}</h2>
        <div className="question-popup-answer">{question.a}</div>
      </MMPopup>
    </div>
  );
}

export default Question;
