import React from "react";
import MainLayout from "../components/layouts/MainLayout";
import HelpDesk from "../components/helpdesk/Helpdesk";
import { useParams } from "react-router-dom";

function IndexPage(props) {
  const { questionSlug } = useParams();

  return (
    <MainLayout page="index">
      <HelpDesk questionSlug={questionSlug} />
    </MainLayout>
  );
}

export default IndexPage;
